import { ConfigurableFocusTrapFactory } from '@angular/cdk/a11y';
import { DOCUMENT } from '@angular/common';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ElRefDirective } from '@app/_directives/el-ref/el-ref.directive';
import { SatPopover, SatPopoverAnchoringService } from '@ncstate/sat-popover';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  addSeconds,
  addYears,
  endOfYear,
  format,
  intervalToDuration,
  isWithinInterval,
  startOfDay,
  startOfYear,
  subYears,
} from 'date-fns/esm';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, first, map } from 'rxjs/operators';
import { Logger, TasksQuery, Time, UserSettingsQuery } from 'timeghost-api';
import { parseDurationAsFormat } from '../duration-input-control/duration-input-utils';

const log = new Logger('TimeEditPopoverComponent');
@UntilDestroy()
@Component({
  selector: 'tg-time-edit-popover',
  templateUrl: './time-edit-popover.component.html',
  styleUrls: ['./time-edit-popover.component.scss'],
  providers: [{ provide: SatPopoverAnchoringService, useExisting: false }],
})
export class TimeEditPopoverComponent extends SatPopover implements OnInit, AfterContentInit, AfterViewInit {
  private _vcRef: ViewContainerRef;
  constructor(
    private _el: ElementRef,
    private userSettingsQuery: UserSettingsQuery,
    private tasksQuery: TasksQuery,
    _focusTrapFactory: ConfigurableFocusTrapFactory,
    _anchoringService: SatPopoverAnchoringService,
    _viewContainerRef: ViewContainerRef,
    @Optional() @Inject(DOCUMENT) _document: any,
    private cdr: ChangeDetectorRef
  ) {
    super(_focusTrapFactory, _anchoringService, _viewContainerRef, '200ms cubic-bezier(0.25, 0.8, 0.25, 1)', _document);
    this._vcRef = _viewContainerRef;
    this.yAlign = 'start';
  }
  group = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
    duration: new FormControl(),
    date: new FormControl(),
    stateVisibleMode: new FormControl(true),
  });
  availableDates = (d: Date) =>
    isWithinInterval(d, {
      start: startOfYear(subYears(new Date(), 1)),
      end: this.userSettingsQuery.getValue()?.workspace?.settings?.allowFutureTimeTracking
        ? endOfYear(addYears(new Date(), 1))
        : new Date(),
    });
  private _item = new BehaviorSubject<Time>(null);
  readonly item$ = this._item.asObservable().pipe(distinctUntilChanged());
  readonly item$date = this.item$.pipe(map((x) => (x.start ? new Date(x.start) : new Date())));
  get item() {
    return this._item.getValue();
  }
  @Input()
  set item(val: Time) {
    this._item.next(val);
    if (val) this.refreshGroup(val as any);
  }
  @Input()
  returnOnSubmit: boolean = true;
  private _focusFieldName: string = null;
  public get focusFieldName(): string {
    return this._focusFieldName;
  }
  public set focusFieldName(v: string) {
    this._focusFieldName = v;
  }
  get stateVisibleMode() {
    return this.group.value.stateVisibleMode;
  }
  @Output()
  stateVisibleModeChange = new EventEmitter();
  @Input()
  set stateVisibleMode(v: boolean) {
    this.group.patchValue({
      stateVisibleMode: v,
    });
    this.stateVisibleModeChange.emit(v);
  }

  refreshGroup(
    { start, end, date, timeDiff, inputMode }: Time & { date: string; inputMode: string },
    _stateVisibleMode?: boolean
  ) {
    const stateVisibleMode = _stateVisibleMode ?? this.stateVisibleMode;
    const dt = {
      start: new Date(start),
      end: new Date(end),
      date: new Date(date ?? startOfDay(new Date(start))),
    };
    const duration =
      timeDiff !== undefined
        ? timeDiff
        : ((d) => (~~d.hours * 60 + ~~d.minutes) * 60)(intervalToDuration({ start: dt.start, end: dt.end }));
    const value = {
      start: format(dt.start, 'HH:mm'),
      end: format(dt.end, 'HH:mm'),
      duration:
        inputMode === 'duration'
          ? parseDurationAsFormat(duration / 60)
          : format(addSeconds(startOfDay(dt.start.getTime()), duration), 'HH:mm'),
      date: startOfDay(dt.date.getTime()),
    };
    if (JSON.stringify(this.group.value) == JSON.stringify(value)) {
      return;
    }
    this.group.setValue(
      {
        ...value,
        stateVisibleMode:
          stateVisibleMode ?? (this.mode === 'range_optional' && (value.start !== '00:00' || value.end !== '00:00')),
      },
      { emitEvent: false }
    );
    this.group.setMustChange(this.group.value);
    if (this.group.dirty) this.group.markAsPristine();
    this.group.updateValueAndValidity();
  }
  get mode() {
    return this.userSettingsQuery.getValue()?.workspace.settings?.timesMode;
  }
  ngOnInit(): void {
    this.hasBackdrop = true;
    this.lockAlignment = false;
    this.closed.subscribe(() => {
      this.focusFieldName = null;
    });
  }
  ngAfterContentInit(): void {}
  ngAfterViewInit(): void {}
  toggle(el?: ElementRef, dataContext?: { time: Time; [key: string]: any }) {
    if (el) {
      this.setCustomAnchor(this._vcRef, el);
    }
    if (dataContext) {
      if (dataContext.time) this.item = dataContext.time;
      if (dataContext.field) this.focusFieldName = dataContext.field;
      // @ts-ignore
      if (dataContext.time) this.stateVisibleMode = dataContext.time.inputMode === 'range';
    }
    super.toggle();
    return {
      afterClosed: () =>
        this.closed.pipe(
          first(),
          map(() => {
            const data = this.group.getRawValue();
            if (this.group.invalid) return null;
            const inputMode = this.stateVisibleMode ? 'range' : 'duration';
            return this.mode === 'range_optional'
              ? this.stateVisibleMode
                ? { ...data, duration: null, inputMode }
                : { ...data, start: '00:00', end: '00:00', inputMode }
              : data;
          })
        ),
    };
  }
  trackId(index: number, { id }: { id: string }) {
    return id;
  }
  close(data?: any) {
    this.item = null;
    this.group.reset({ start: '00:00', end: '00:00', date: new Date() });
    const inputMode = this.stateVisibleMode ? 'range' : 'duration';
    return super.close({ ...data, inputMode });
  }
  updateDate(date: Date) {
    return this.group.patchValue({
      date: startOfDay(date),
    });
  }
  selectInput(ev: Event, timeInput: ElRefDirective) {
    const el: HTMLElement = timeInput.elementRef.nativeElement;
    if (!el) {
      return;
    }
    const input = el.querySelector('input');
    if (input) {
      ev.preventDefault();
      input.select();
    }
  }
}
