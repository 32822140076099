import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomDirectivesModule } from '@app/_directives/custom-directives.module';
import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { TagPickerDialogComponent } from './tag-picker-dialog.component';

@NgModule({
  declarations: [TagPickerDialogComponent],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, CustomDirectivesModule, TranslateModule],
  exports: [TagPickerDialogComponent],
})
export class TagPickerDialogModule {}
